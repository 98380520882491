<div id="{{uid}}" class="card shadow border-{{colour}} mb-1 outer-gd-card h-100">
  <div class="card-body mb-0 my-auto text-{{colour}} header">
    <ejs-tooltip [isSticky]="false">
      <ng-template #content>
        <div>
          <p>Represents the Number of {{headerText}} Connected to IoT Hub.</p>
        </div>
      </ng-template>
      <em class="fa-solid fa-circle-info"></em>
    </ejs-tooltip>
    <h2 class="col-7 h5 my-auto align-middle">{{headerText}}</h2>
    
    <span class="col-2 my-auto h5 align-middle text-center">  
      <span *ngIf="isBusy; then thenBlock else elseBlock"></span>
      <ng-template #thenBlock><em class="fa fa-spinner fa-pulse" aria-hidden="true"></em></ng-template>
      <ng-template #elseBlock>{{value | shortNumber}}</ng-template>
    </span>
    
  <span class=" col-2 h3 my-auto float-end"><em class="{{icon}} text-{{colour}} float-end"></em></span>
  </div>
</div>
