<div>
    <div class='modal-header'>
        <h5 class='modal-title font-weight-bold m-0' id='modal-basic-title'>{{data.subject}}</h5>
        <button type='button' class='close' aria-label='Close' (click)='activeModal.dismiss()'>
            <span aria-hidden='true'>&times;</span>
          </button>
    </div>
    <div class='modal-body'>
        <ng-container *ngIf="userData.data?.length > 0; then gridTemplate else loading"></ng-container>   
    </div>
    <div class='modal-footer'>
        <button ejs-button cssClass="e-info" id="btnResend" name="resend"
        class="btn-text" [disabled]="!failedAnnouncement" (click)="resubmitUserAnnouncement(); this.activeModal.close(true);">
            Resend
        </button>
        <button type="button" cssClass="e-info" id="btnCancel" name="cancel"
        class="btn btn-secondary" (click)="this.activeModal.close(false);">
            Cancel
        </button>
    </div>
</div>

<ng-template #gridTemplate>
    <ejs-grid #grid [dataSource]="userData.data" [pageSettings]="pagerSettings" [allowPaging]="userData.paging"
        [allowSorting]="true" (actionBegin)="actionBegin($event)" (dataBound)='grdUserListDataBound()'>
        <e-columns>
            <e-column field="user.firstName" headerText="First Name" width="40"></e-column>
            <e-column field="user.lastName" headerText="Last Name" width="70"></e-column>
            <e-column field="user.email" headerText="Email" width="90"></e-column>
            <e-column field="user.group.name" headerText="Role" width="50"></e-column>
            <e-column field="receiptStatus" headerText="Status" width="30"></e-column>
            <e-column field="dateTimeSent" headerText="Date Sent (UTC)" width="70">
                <ng-template #template let-data>
                    {{data.dateTimeSent | dateFormat}}
                </ng-template>
            </e-column>
        </e-columns>
    </ejs-grid>
</ng-template>

<ng-template #loading>
    <p class="center-text d-flex justify-content-center"><em class="fa fa-3x fa-spinner fa-pulse"
        aria-hidden="true"></em></p>  
</ng-template>
