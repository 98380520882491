import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getUid } from '@syncfusion/ej2-grids';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IApplianceFirmwareResults } from '../types/iApplianceFirmwareResults';
import { IBluetoothFirmwareResults } from '../types/iBluetoothFirmwareResults';
import { IDirectFirmware } from '../types/iDirectFirmware';
import { IHubFirmwareResults } from '../types/iHubFirmwareResults';

@Injectable({
  providedIn: 'root'
})
export class FirmwareService {

  constructor(private http: HttpClient) {

  }

  public GetApplianceReleaseGroups(applianceTypeId: string): Observable<any> {
    return this.http.get<any>(this.EndPoint('GetApplianceReleaseGroups/' + applianceTypeId));
  }

  public GetApplianceReleaseHistory(releaseGroup: string): Observable<any> {
    return this.http.get<any>(this.EndPoint('GetApplianceReleaseHistory/' + releaseGroup));
  }

  public GetApplianceReleaseGroupResults(releaseGroup: string): Observable<any> {
    return this.http.get<any>(this.EndPoint('GetApplianceReleaseGroupResults/' + releaseGroup));
  }

  public GetApplianceReleaseGroupDetails(releaseGroup: string): Observable<any> {
    return this.http.get<any>(this.EndPoint('GetApplianceReleaseGroupDetails/' + releaseGroup));
  }

  public DirectApplianceSearch(
    applianceId: string,
    applianceTypeId: string,
    applianceModelId: string,
    firmwareVersion: string,
    userEmail: string,
    releaseGroupId: string,
    searchLimit: number,
    pageNum: number,
    orderProperty: string,
    desc: boolean): Observable<any> {
    let params = new HttpParams();
    if (applianceId) { params = params.append('applianceId', applianceId); }
    if (applianceTypeId) { params = params.append('applianceTypeId', applianceTypeId); }
    if (applianceModelId) { params = params.append('applianceModelId', applianceModelId); }
    if (firmwareVersion) { params = params.append('firmwareVersion', firmwareVersion); }
    if (userEmail) { params = params.append('userEmail', encodeURIComponent(userEmail)); }
    if (releaseGroupId) { params = params.append('releaseGroupId', releaseGroupId); }
    if (searchLimit) { params = params.append('searchLimit', searchLimit); }
    if (pageNum) { params = params.append('pageNum', pageNum); }
    if (orderProperty) { params = params.append('orderProperty', orderProperty); }
    if (desc) { params = params.append('desc', desc); }
    return this.http.get<any>(this.EndPoint('DirectApplianceSearch'), { params });
  }

  public CheckIfApplianceFirmwareIsEnabled(releaseGroup: string): Observable<any> {
    return this.http.get<any>(this.EndPoint('CheckIfApplianceFirmwareIsEnabled/' + releaseGroup));
  }

  public GetApplianceReleaseGroupFirmwareVersion(releaseGroup: string): Observable<any> {
    return this.http.get(this.EndPoint('GetApplianceReleaseGroupFirmwareVersion/' + releaseGroup), { responseType: 'text' });
  }

  public AddApplianceReleaseGroup(applianceTypeId: string, releaseGroupName: string, externalId: string): Observable<any> {
    return this.http.get(this.EndPoint('AddApplianceReleaseGroup/' + applianceTypeId + '/' + releaseGroupName + '/' + externalId), { responseType: 'json' });
  }

  public DeleteApplianceReleaseGroup(releaseGroup: string): Observable<any> {
    return this.http.get(this.EndPoint('DeleteApplianceReleaseGroup/' + releaseGroup), { responseType: 'text' });
  }

  public AddApplianceToReleaseGroup(applianceId: string, releaseGroup: string): Observable<any> {
    return this.http.get<any>(this.EndPoint('AddApplianceToReleaseGroup/' + applianceId + '/' + releaseGroup));
  }

  public RemoveApplianceFromReleaseGroup(applianceId: string, releaseGroup: string): Observable<any> {
    return this.http.get<any>(this.EndPoint('RemoveApplianceFromReleaseGroup/' + applianceId + '/' + releaseGroup));
  }

  public GetApplianceFirmware(applianceTypeId: string): Observable<any> {
    return this.http.get<any>(this.EndPoint('GetApplianceFirmware/' + applianceTypeId));
  }

  public ReleaseDirectApplianceFirmware(
    releaseGroupId: string,
    firmwareId: string,
    hardware: string,
    isDirectAppliance: boolean,
    isGeneralRelease: boolean,
    isNow: boolean,
    releaseTime: Date): Observable<any> {
    let firmware = {
      releaseGroupId: releaseGroupId,
      firmwareId: firmwareId,
      hardwareRevision: hardware != null ? hardware : '1.0',
      isDirectAppliance: isDirectAppliance,
      isGeneralRelease: isGeneralRelease,
      isNow: isNow,
      releaseTime: releaseTime,
    };

    return this.http.post(this.EndPoint('ReleaseDirectApplianceFirmware'), firmware);
  }

  public UpdateDirectApplianceGeneralRelease(firmwareId: string) {
    return this.http.patch<any>(this.EndPoint('UpdateDirectApplianceGeneralRelease/' + firmwareId), null);
  }

  public UploadApplianceFirmwareFile(file: FormData, typeId: string): Observable<any> {
    return this.http.post<any>(this.EndPoint('UploadApplianceFirmwareFile/' + typeId), file);
  }

  public AddApplianceFirmwareRelease(firmware: any): Observable<any> {
    return this.http.post<any>(this.EndPoint('AddApplianceFirmwareRelease'), firmware);
  }

  public ToggleApplianceReleaseState(firmwareId: string, isEnabled: boolean): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }
    return this.http.patch<any>(this.EndPoint('ToggleApplianceFirmwareState/' + firmwareId + '/' + isEnabled), null, httpOptions);
  }

  public GetHubReleaseGroups(hubType: string): Observable<any> {
    return this.http.get<any>(this.EndPoint('GetHubReleaseGroups/' + hubType));
  }

  public GetHubReleaseHistory(releaseGroup: string): Observable<any> {
    return this.http.get<any>(this.EndPoint('GetHubReleaseHistory/' + releaseGroup));
  }

  public GetHubReleaseGroupResults(releaseGroup: string): Observable<any> {
    return this.http.get<any>(this.EndPoint('GetHubReleaseGroupResults/' + releaseGroup));
  }

  public GetHubReleaseGroupDetails(releaseGroup: string): Observable<any> {
    return this.http.get<any>(this.EndPoint('GetHubReleaseGroupDetails/' + releaseGroup));
  }

  public HubSearch(
    hubId: string,
    firmwareVersion: string,
    userEmail: string,
    postCode: string,
    hubType: string,
    releaseGroupId: string,
    searchLimit: number,
    pageNum: number,
    orderProperty: string,
    desc: boolean): Observable<any> {
    let params = new HttpParams();
    if (hubId) { params = params.append('hubId', hubId); }
    if (userEmail) { params = params.append('userEmail', encodeURIComponent(userEmail)); }
    if (postCode) { params = params.append('postCode', postCode); }
    if (hubType) { params = params.append('hubType', hubType); }
    if (releaseGroupId) { params = params.append('releaseGroupId', releaseGroupId); }
    if (searchLimit) { params = params.append('searchLimit', searchLimit); }
    if (pageNum) { params = params.append('pageNum', pageNum); }
    if (orderProperty) { params = params.append('orderProperty', orderProperty); }
    if (desc) { params = params.append('desc', desc); }
    return this.http.get<any>(this.EndPoint('HubSearch'), { params });
  }

  public CheckIfHubFirmwareIsEnabled(releaseGroup: string): Observable<any> {
    return this.http.get<any>(this.EndPoint('CheckIfHubFirmwareIsEnabled/' + releaseGroup));
  }

  public GetHubReleaseGroupFirmwareVersion(releaseGroup: string): Observable<any> {
    return this.http.get(this.EndPoint('GetHubReleaseGroupFirmwareVersion/' + releaseGroup), { responseType: 'text' });
  }

  public AddHubToReleaseGroup(hubId: string, releaseGroup: string): Observable<any> {
    return this.http.get<any>(this.EndPoint('AddHubToReleaseGroup/' + hubId + '/' + releaseGroup));
  }

  public RemoveHubFromReleaseGroup(hubId: string, releaseGroup: string): Observable<any> {
    return this.http.get<any>(this.EndPoint('RemoveHubFromReleaseGroup/' + hubId + '/' + releaseGroup));
  }

  public AddHubReleaseGroup(hubType: string, releaseGroupName: string): Observable<any> {
    return this.http.post(this.EndPoint('AddHubReleaseGroup/' + hubType + '/' + releaseGroupName), { responseType: 'json' });
  }

  public DeleteHubReleaseGroup(releaseGroup: string): Observable<any> {
    return this.http.get(this.EndPoint('DeleteHubReleaseGroup/' + releaseGroup), { responseType: 'text' });
  }

  public GetHubFirmware(hubType: string): Observable<any> {
    return this.http.get(this.EndPoint('GetHubFirmware/' + hubType));
  }

  public ReleaseHubFirmware(
    releaseGroupId: string,
    firmwareId: string,
    hardware: string,
    hubType: string,
    isGeneralRelease: boolean,
    isNow: boolean,
    releaseTime: Date): Observable<any> {
    let firmware = {
      releaseGroupId: releaseGroupId,
      firmwareId: firmwareId,
      hardwareRevision: hardware != null ? hardware : '1.0',
      hubType: hubType,
      isGeneralRelease: isGeneralRelease,
      isNow: isNow,
      releaseTime: releaseTime,
    };
    return this.http.post<any>(this.EndPoint('ReleaseHubFirmware'), firmware);
  }

  public UpdateHubGeneralRelease(firmwareId: string) {
    return this.http.patch<any>(this.EndPoint('UpdateHubGeneralRelease/' + firmwareId), null);
  }

  public UploadHubFirmwareFile(file: FormData): Observable<any> {
    return this.http.post<any>(this.EndPoint('UploadHubFirmwareFile'), file);
  }

  public UploadGatewayV2FirmwareFile(file: FormData): Observable<any> {
    return this.http.post<any>(this.EndPoint('UploadGatewayV2FirmwareFile'), file);
  }

  public UploadGatewayV1FirmwareFile(file: FormData): Observable<any> {
    return this.http.post<any>(this.EndPoint('UploadLTEGatewayV1FirmwareFile'), file);
  }

  public AddHubFirmwareRelease(firmware: any): Observable<any> {
    return this.http.post<any>(this.EndPoint('AddHubFirmwareRelease'), firmware);
  }

  public ToggleHubReleaseState(firmwareId: string, isEnabled: boolean): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }
    return this.http.patch<any>(this.EndPoint('ToggleHubFirmwareState/' + firmwareId + '/' + isEnabled), null, httpOptions);
  }

  public GetBluetoothReleaseGroups(bluetoothModel: string): Observable<any> {
    return this.http.get<any>(this.EndPoint('GetBluetoothReleaseGroups/' + bluetoothModel));
  }

  public GetBluetoothReleaseGroupResults(releaseGroup: string): Observable<any> {
    return this.http.get<any>(this.EndPoint('GetBluetoothReleaseGroupResults/' + releaseGroup));
  }

  public ApplianceSearch(
    applianceId: string,
    applianceModelId: string,
    firmwareVersion: string,
    userEmail: string,
    releaseGroupId: string,
    searchLimit: number,
    pageNum: number,
    orderProperty: string,
    desc: boolean): Observable<any> {
    let params = new HttpParams();
    if (applianceId) { params = params.append('applianceId', applianceId); }
    if (applianceModelId) { params = params.append('applianceModelId', applianceModelId); }
    if (firmwareVersion) { params = params.append('firmwareVersion', firmwareVersion); }
    if (userEmail) { params = params.append('userEmail', encodeURIComponent(userEmail)); }
    if (releaseGroupId) { params = params.append('releaseGroupId', releaseGroupId); }
    if (searchLimit) { params = params.append('searchLimit', searchLimit); }
    if (pageNum) { params = params.append('pageNum', pageNum); }
    if (orderProperty) { params = params.append('orderProperty', orderProperty); }
    if (desc) { params = params.append('desc', desc); }
    return this.http.get<any>(this.EndPoint('ApplianceSearch'), { params });
  }

  public GetBluetoothReleaseGroupDetails(releaseGroup: string): Observable<any> {
    return this.http.get<any>(this.EndPoint('GetBluetoothReleaseGroupDetails/' + releaseGroup));
  }

  public CheckIfBluetoothFirmwareIsEnabled(releaseGroup: string): Observable<any> {
    return this.http.get<any>(this.EndPoint('CheckIfBluetoothFirmwareIsEnabled/' + releaseGroup));
  }

  public GetBluetoothReleaseGroupFirmwareVersion(releaseGroup: string): Observable<any> {
    return this.http.get(this.EndPoint('GetBluetoothReleaseGroupFirmwareVersion/' + releaseGroup), { responseType: 'text' });
  }

  public AddBluetoothDeviceToReleaseGroup(bluetoothId: string, releaseGroup: string): Observable<any> {
    return this.http.get<any>(this.EndPoint('AddBluetoothDeviceToReleaseGroup/' + bluetoothId + '/' + releaseGroup));
  }

  public RemoveBluetoothDeviceFromReleaseGroup(bluetoothId: string, releaseGroup: string): Observable<any> {
    return this.http.get<any>(this.EndPoint('RemoveBluetoothDeviceFromReleaseGroup/' + bluetoothId + '/' + releaseGroup));
  }

  public AddBluetoothReleaseGroup(bluetoothModel: string, releaseGroupName: string, externalId: string): Observable<any> {
    return this.http.get(this.EndPoint('AddBluetoothReleaseGroup/' + bluetoothModel + '/' + releaseGroupName + '/' + externalId), { responseType: 'json' });
  }

  public DeleteBluetoothReleaseGroup(releaseGroup: string): Observable<any> {
    return this.http.get(this.EndPoint('DeleteBluetoothReleaseGroup/' + releaseGroup), { responseType: 'text' });
  }

  public GetBluetoothFirmware(bluetoothTypeId: string): Observable<any> {
    return this.http.get<any>(this.EndPoint('GetBluetoothFirmware/' + bluetoothTypeId));
  }

  public GetBluetoothAssociatedModels(firmwareId: string): Observable<any> {
    return this.http.get<any>(this.EndPoint('GetAssociatedBluetoothModels/' + firmwareId));
  }

  public UploadBluetoothFirmwareFile(file: FormData): Observable<any> {
    return this.http.post<any>(this.EndPoint('UploadBluetoothFirmwareFile'), file);
  }

  public AddBluetoothFirmwareRelease(firmware: any): Observable<any> {
    return this.http.post<any>(this.EndPoint('AddBluetoothFirmwareRelease'), firmware);
  }

  public async EditBluetoothFirmwareRelease(id: string, releaseNotes: string, minAppVersion: string, models: string[], seriesIdentifiers: string, defaultSeriesIdentifier: boolean): Promise<any> {
    let firmwareDetails = {
          id: id,
          releaseNotes: releaseNotes,
          minAppVersion: minAppVersion,
          Models: models,
          seriesIdentifiers: seriesIdentifiers,
          defaultSeriesIdentifier: defaultSeriesIdentifier
        }
    
        return this.http.post(this.EndPoint('EditBluetoothFirmwareRelease'), firmwareDetails).toPromise();
  }

  public CreateBluetoothReleaseHistory(releaseGroupId: string, firmwareId: string, isNow: boolean, releaseTime: Date): Observable<any> {
    let firmware = {
      releaseGroupId: releaseGroupId,
      firmwareId: firmwareId,
      isNow: isNow,
      releaseTime: releaseTime,
    };

    return this.http.post(this.EndPoint('CreateBluetoothReleaseHistory'), firmware);
  }

  public ToggleBluetoothReleaseState(firmwareId: string, isEnabled: boolean): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }
    return this.http.patch<any>(this.EndPoint('ToggleBluetoothFirmwareState/' + firmwareId + '/' + isEnabled), null, httpOptions);
  }

  public GetZigbeeFirmware(bluetoothTypeId: string): Observable<any> {
    return this.http.get<any>(this.EndPoint('GetZigbeeFirmware/' + bluetoothTypeId));
  }

  public GetZigbeeAssociatedModels(firmwareId: string): Observable<any> {
    return this.http.get<any>(this.EndPoint('GetAssociatedZigbeeModels/' + firmwareId));
  }

  public async EditZigbeeFirmwareRelease(id: string, releaseNotes: string, minAppVersion: string, models: string[], seriesIdentifiers: string, defaultSeriesIdentifier: boolean): Promise<any> {
    let firmwareDetails = {
          id: id,
          releaseNotes: releaseNotes,
          minAppVersion: minAppVersion,
          Models: models,
          seriesIdentifiers: seriesIdentifiers,
          defaultSeriesIdentifier: defaultSeriesIdentifier
        }
    
        return this.http.post(this.EndPoint('EditZigbeeFirmwareRelease'), firmwareDetails).toPromise();
  }

  public UploadZigbeeFirmwareFile(file: FormData): Observable<any> {
    return this.http.post<any>(this.EndPoint('UploadZigbeeFirmwareFile'), file);
  }

  public AddZigbeeFirmwareRelease(firmware: any): Observable<any> {
    return this.http.post<any>(this.EndPoint('AddZigbeeFirmwareRelease'), firmware);
  }

  public UpdateDirectFirmware(firmware: IDirectFirmware) {

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }
    return this.http.patch<any>(this.EndPoint('UpdateDirectFirmware'), firmware, httpOptions);
  }

  public CheckIfFileExists(containerName: string, blobName: string, folderName?: string): Observable<boolean> {
    let params = new HttpParams();
    params = params.append('containerName', containerName);
    params = params.append('blobName', blobName);
    if (folderName) { params = params.append('folderName', folderName) }
    return this.http.get<boolean>(this.EndPoint('CheckIfFileExists'), { params });
  }

  public getDirectApplianceFirmwareHistory(searchLimit: number, pageNum: number): Observable<any> {
    return this.http.get<any>(this.EndPoint('GetDirectApplianceFirmwareActivity/' + searchLimit + '/' + pageNum));
  }

  public getHubFirmwareHistory(searchLimit: number, pageNum: number): Observable<any> {
    return this.http.get<any>(this.EndPoint('GetHubFirmwareActivity/' + searchLimit + '/' + pageNum));
  }

  public getBluetoothFirmwareHistory(searchLimit: number, pageNum: number): Observable<any> {
    return this.http.get<any>(this.EndPoint('GetBluetoothFirmwareActivity/' + searchLimit + '/' + pageNum));
  }

  private EndPoint(actionName: string) {
    return environment.apiBaseUrl + 'admin/Firmware/' + actionName;
  }
}
