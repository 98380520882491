<div>
    <div class="modal-header">
        <h5 class="modal-title font-weight-bold m-0" id="modal-basic-title">{{deviceOverview.deviceCategory}} Search for - {{deviceOverview.id}}</h5>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-3 pb-3">
                <b>Device Id: </b><br/>
                    <div [ngSwitch]="deviceOverview?.deviceCategory">
                        <div *ngSwitchCase="'Appliance'">
                            <a *ngIf="allowDirectApplianceManagementRead" class="link-primary px-1" (click)="close()" [routerLink]="[directApplianceDetails, deviceOverview?.id]">{{deviceOverview?.id}}</a>
                            <p *ngIf="!allowDirectApplianceManagementRead" class="px-1">{{deviceOverview?.id}}</p>
                        </div>
                        <div *ngSwitchCase="'Hub'">
                            <a *ngIf="allowHubManagementRead" class="link-primary px-1" (click)="close()" [routerLink]="[hubDetails, deviceOverview?.id]">{{deviceOverview?.id}}</a>
                            <p *ngIf="!allowHubManagementRead" class="px-1">{{deviceOverview?.id}}</p>
                        </div>
                        <div *ngSwitchCase="'Bluetooth'">
                            <a *ngIf="allowAdminApplianceManagementRead" class="link-primary px-1" (click)="close()" [routerLink]="[applianceDetails, deviceOverview?.id]">{{deviceOverview?.id}}</a>
                            <p *ngIf="!allowAdminApplianceManagementRead" class="px-1">{{deviceOverview?.id}}</p>
                        </div>
                    </div>
            </div>
            <div class="col-3 pb-3">
                <b>Device Type: </b><br/><p class="px-1">{{deviceOverview?.deviceType}}</p>
            </div>
            <div *ngIf="deviceOverview?.deviceModel" class="col-3 pb-3">
                <b>Device Model: </b><br/><p class="px-1">{{deviceOverview?.deviceModel}}</p>
            </div>
            <div *ngIf="deviceOverview?.releaseGroupName" class="col-3 pb-3">
                <b>Release Group Name: </b><br/><a *ngIf="allowDirectApplianceFirmwareReleaseGroupRead || allowHubFirmwareReleaseGroupRead || allowBluetoothApplianceFirmwareReleaseGroupRead" 
                    class="link-primary" (click)="navigateToNewPage(deviceOverview?.releaseGroupId)">{{deviceOverview?.releaseGroupName}}</a>
                <p *ngIf="!allowDirectApplianceFirmwareReleaseGroupRead && !allowHubFirmwareReleaseGroupRead && !allowBluetoothApplianceFirmwareReleaseGroupRead" class="px-1">{{deviceOverview?.releaseGroupName}}</p>
            </div>
            <div class="col-3 pb-3">
                <b>Firmware Version: </b><br/><p class="px-1">{{deviceOverview?.firmwareVersion}}</p>
            </div>
            <div *ngIf="deviceOverview?.seriesIdentifier" class="col-3 pb-3">
                <b>Series Identifier: </b><br/><p class="px-1">{{deviceOverview?.seriesIdentifier}}</p>
            </div>
            <div *ngIf="deviceOverview?.dateAdded" class="col-3 pb-3">
                <b>Date Added (UTC): </b><br/><p class="px-1">{{deviceOverview?.dateAdded | dateFormat}}</p>
            </div>
            <div *ngIf="deviceOverview?.installationDate" class="col-3 pb-3">
                <b>Installation Date (UTC): </b><br/><p class="px-1">{{deviceOverview?.installationDate | dateFormat}}</p>
            </div>
        </div>
    </div>
</div>