import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GridComponent, PageSettingsModel } from '@syncfusion/ej2-angular-grids';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-announcement-list',
  templateUrl: './announcement-list.component.html'
})
export class AnnouncementListComponent implements OnInit {

  @Input() public data;

  @ViewChild('grid')
  private grid: GridComponent;

  public userData = {
    data: null,
    paging: false,
    orderProperty: "dateTime",
    orderDirection: false,
  };

  public pagerSettings: PageSettingsModel = {
    pageSize: 10,
    currentPage: 1,
    pageCount: 8,
    totalRecordsCount: 10,
    pageSizes: [5, 10, 15, 25, 50],
  }

  public failedAnnouncement: boolean = false;

  constructor(
    private userService: UserService,
    public statusModel: NgbActiveModal) { }

  ngOnInit(): void {
    let pageNum = 1;
    let orderProperty = this.userData.orderProperty;
    let desc = this.userData.orderDirection;
    this.getUserData(pageNum, orderProperty, desc);
  }

  public get activeModal() {
    return this.statusModel;
  }

  public async getUserData(pageNum: number, orderProperty: string, desc: boolean) {
    let searchLimit = this.pagerSettings.pageSize;
    let data = await this.userService.getUserList(this.data.id, searchLimit, pageNum, orderProperty, desc).toPromise();
    if (pageNum > 1) {
      let arraySize = (pageNum * searchLimit) - searchLimit;
      this.userData.data = new Array(arraySize);
      this.userData.data = this.userData.data.concat(data.result);
    }
    else {
      this.userData.data = data.result;
      this.pagerSettings.totalRecordsCount = data.totalRecordsCount;
      if (data.totalRecordsCount > searchLimit) {
        this.userData.data = this.userData.data.concat(new Array(searchLimit));
      }
    }
    this.userData.data.forEach(x => {
      if (x.dateTimeSent !== null && !x.receiptStatus) {
        this.failedAnnouncement = true;
      }
    });
  }

  public async resubmitUserAnnouncement() {
    await this.userService.resubmitAnnoucement(
      this.data.id,
      this.data.message,
      this.data.subject,
      this.data.application,
      this.data.communicationSource,
      this.data.messageType).toPromise();
  }

  public actionBegin(event: any) {
    let pageNum: number;
    let property = this.userData.orderProperty;
    let desc = this.userData.orderDirection;
    switch (event.requestType) {
      case "paging":
        if (event.previousPage != event.currentPage) {
          this.pagerSettings.currentPage = event.currentPage;
          pageNum = event.currentPage;
          if (this.grid.pagerModule.pagerObj.pageSize != this.pagerSettings.pageSize) {
            this.pagerSettings.pageSize = this.grid.pagerModule.pagerObj.pageSize;
            this.pagerSettings.currentPage = event.previousPage;
            pageNum = event.previousPage;
          }
          let pageCount = this.pagerSettings.totalRecordsCount / this.pagerSettings.pageSize;
          if (this.pagerSettings.currentPage > Math.ceil(pageCount)) {
            pageNum = Math.ceil(pageCount);
          }
          this.getUserData(pageNum, property, desc);
        }
        else if (this.grid.pagerModule.pagerObj.pageSize != this.pagerSettings.pageSize) {
          this.pagerSettings.pageSize = this.grid.pagerModule.pagerObj.pageSize;
          this.pagerSettings.currentPage = event.previousPage;
          pageNum = event.previousPage;
          let pageCount = this.pagerSettings.totalRecordsCount / this.pagerSettings.pageSize;
          if (this.pagerSettings.currentPage > Math.ceil(pageCount)) {
            pageNum = Math.ceil(pageCount);
          }
          this.getUserData(pageNum, property, desc);
        }
        break;
      case "sorting":
        event.cancel = true;
        if (event.columnName == undefined) {
          property = "dateTimeSent";
          desc = false;
        }
        else {
          if (property.toLowerCase() == event.columnName.toLowerCase()) {
            desc = !desc;
          }
          else {
            property = event.columnName.charAt(0).toUpperCase() + event.columnName.slice(1);
            desc = event.direction == "Descending";
          }
        }
        this.userData.orderProperty = property;
        this.userData.orderDirection = desc;
        pageNum = this.pagerSettings.currentPage;
        this.getUserData(pageNum, property, desc);
        this.customSortArrows(event.columnName, desc);
        break;
    }
  }

  public customSortArrows(columnName: any, desc: boolean) {
    let header = this.grid.getColumnHeaderByField(columnName);
    let headerRow = header?.parentElement;
    if (headerRow) {
      for (let i = 0; i < headerRow.children.length; i++) {
        let child = headerRow.children[i];
        let sortArrow = child.firstElementChild.getElementsByTagName('em');
        if (sortArrow.length > 0) {
          child.firstElementChild.removeChild(sortArrow[0]);
        }
      }
    }
    if (desc) {
      let arrow = document.createElement('em');
      arrow.classList.add('fas', 'fa-arrow-down', 'pl-4');
      header.firstElementChild.append(arrow);
    }
    else {
      let arrow = document.createElement('em');
      arrow.classList.add('fas', 'fa-arrow-up', 'pl-4');
      header.firstElementChild.append(arrow);
    }
  }

  public grdUserListDataBound() {
    if (this.pagerSettings.totalRecordsCount > this.pagerSettings.pageSize) {
      this.userData.paging = true;
      if (this.grid?.pagerModule) {
        this.grid.pagerModule.pagerObj.totalRecordsCount = this.pagerSettings.totalRecordsCount;
      }
    }
  }

}
