<div class="row">
  <div class="not-found-alert">
    <app-panel [isBusy]="false" [removePadding]="false">
      <div class="text-center">
        <h2>Not Found</h2>
        <p>Sorry, the page you are looking for, cannot be found.</p>
        <br>
        <div class="row">
          <div class="col-lg-6">
            <button ejs-button cssClass="e-primary" routerLink="/dashboard">
              Go Home
            </button>
          </div>
          <div class="col-lg-6">
            <button ejs-button cssClass="e-danger" (click)="logOut()">
              Log Out
            </button>
          </div>
        </div>
      </div>
    </app-panel>
  </div>
</div>
