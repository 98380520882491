import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IBluetoothSearchCriteria } from '../types/iBluetoothSearchCriteria';

@Injectable({
  providedIn: 'root'
})
export class ApplianceService {

  constructor(private http: HttpClient) { }

  public GetAppliance(applianceId: string): Observable<any>{
    return this.http.get<any>(this.endPoint('GetAppliance/' + applianceId));
  }

  public GetBluetoothTypes(): Observable<any>{
    return this.http.get<any>(this.endPoint('GetBluetoothTypes'));
  }

  public GetBluetoothModels(bluetoothType: string): Observable<any>{
    return this.http.get<any>(this.endPoint('GetBluetoothModels/' + bluetoothType));
  }

  public GetBluetoothModelDetails(bluetoothModel: string): Observable<any>{
    return this.http.get<any>(this.endPoint('GetBluetoothModelDetails/' + bluetoothModel));
  }

  public BluetoothSearch(
    applianceId: string,
    applianceType: string,
    applianceModel: string,
    firmwareVersion: string,
    userEmail: string,
    releaseGroupId: string,
    searchLimit: number,
    pageNum: number,
    orderProperty: string,
    desc: boolean): Observable<any>{
      let params = new HttpParams();
      if (applianceId) { params = params.append('applianceId', applianceId); }
      if (applianceType) { params = params.append('applianceTypeId', applianceType); }
      if (applianceModel) { params = params.append('applianceModelId', applianceModel); }
      if (firmwareVersion) { params = params.append('firmwareVersion', firmwareVersion); }
      if (userEmail) { params = params.append('userEmail', encodeURIComponent(userEmail)); }
      if (releaseGroupId) { params = params.append('releaseGroupId', releaseGroupId); }
      if (searchLimit) {params = params.append('searchLimit', searchLimit); }
      if (pageNum) {params = params.append('pageNum', pageNum); }
      if (orderProperty) {params = params.append('orderProperty', orderProperty); }
      if (desc) {params = params.append('desc', desc); }

    return this.http.get<any>(this.endPoint('BluetoothSearch'), {params});
  }

  public GetZigbeeTypes(): Observable<any>{
    return this.http.get<any>(this.endPoint('GetZigbeeTypes'));
  }

  public GetApplianceTelemetry(applianceId: string, dateRange: number): Observable<any> {
    return this.http.get<any>(this.endPoint('GetApplianceTelemetry/' + applianceId + '/' + dateRange));
  }

  private endPoint(action: string): string {
    return environment.apiBaseUrl + 'admin/appliance/' + action;
    
  }
}
