<ng-container *ngTemplateOutlet="modalHeader"></ng-container>
<div *ngIf="!models">
    <div class='modal-body'>
        <div class="row">
            <ng-container *ngTemplateOutlet="uploadForm"></ng-container>
            <ng-container *ngTemplateOutlet="modelSelect"></ng-container>
        </div>
    </div>
</div>
<div *ngIf="models">
    <ng-container *ngTemplateOutlet="modelView"></ng-container>
</div>
<ng-container *ngTemplateOutlet="modalFooter"></ng-container>

<ng-template #modalHeader>
    <div class='modal-header'>
        <div *ngIf="bluetoothType">
            <ng-container *ngIf="bluetoothFirmware != null; then showBluetooth; else addFile"></ng-container>
        </div>
        <div *ngIf="zigbeeType">
            <ng-container *ngIf="zigbeeFirmware != null; then showZigbee; else addFile"></ng-container>
        </div>
        <div *ngIf="applianceType">
            <ng-container *ngIf="applianceFirmware != null; then showAppliance; else addFile"></ng-container>
        </div>
        <div *ngIf="hubType">
            <ng-container *ngIf="hubFirmware != null; then showHub; else addFile"></ng-container>
        </div>
        <div *ngIf="models">
            <ng-container *ngIf="models != null; then showModels; else addFile"></ng-container>
        </div>
        <button type='button' class='btn-close' aria-label='Close' (click)='activeModal.dismiss()'>
        </button>
    </div>
</ng-template>

<ng-template #showBluetooth>
    <h5 class='modal-title fw-bold m-0' id='modal-basic-title'>{{ bluetoothFirmware.blobName }}</h5>
</ng-template>
<ng-template #showZigbee>
    <h5 class='modal-title fw-bold m-0' id='modal-basic-title'>{{ zigbeeFirmware.blobName }}</h5>
</ng-template>
<ng-template #showAppliance>
    <h5 class='modal-title fw-bold m-0' id='modal-basic-title'>{{ applianceFirmware.blobName }}</h5>
</ng-template>
<ng-template #showHub>
    <h5 class='modal-title fw-bold m-0' id='modal-basic-title'>{{ hubFirmware.blobName }}</h5>
</ng-template>
<ng-template #showModels>
    <h5 class='modal-title fw-bold m-0' id='modal-basic-title'>Associated Models</h5>
</ng-template>
<ng-template #addFile>
    <h5 class='modal-title fw-bold m-0' id='modal-basic-title'>Add Firmware File</h5>
</ng-template>

<ng-template #uploadForm>
    <div class="col-md-6">
        <div class="row">
            <div *ngIf="bluetoothFirmware == null && applianceFirmware == null">
                <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload>
                <div class="file-upload px-2 ms-2 text-{{uploadDetails.textColor}}">
                    <button ejs-button cssClass="e-primary" class="me-2" (click)="fileUpload.click()"> Upload
                    </button>
                    {{ uploadDetails.fileName || "No File Uploaded!"}}
                </div>
            </div>
            <div class="mt-2 ms-3">
                <ejs-checkbox label="General Release" [(ngModel)]="uploadDetails.isGeneralRelease"></ejs-checkbox>
            </div>
        </div>
        <div class="row my-3">
            <div class="col-md-6 mb-2">
                <ejs-textbox placeholder="Notes" cssClass="e-outline" [(ngModel)]="uploadDetails.releaseNotes"
                    id="releaseNotes" floatLabelType="Auto" [multiline]='true'>
                </ejs-textbox>
            </div>
            <div *ngIf="bluetoothType != null || zigbeeType != null" class="col-md-6 mb-2">
                <ejs-textbox #minAppVersion="ngModel" name="minAppVersion" placeholder="Min App Ver"
                    cssClass="e-outline" [(ngModel)]="uploadDetails.minAppVersion" id="minAppVer" floatLabelType="Auto"
                    required pattern="^[0-9]+.[0-9]+.[0-9]+$">
                </ejs-textbox>
                <span class="e-error" *ngIf="minAppVersion.errors && minAppVersion.errors.pattern">Invalid
                    min app version.</span>
                <span class="e-error"
                    *ngIf="minAppVersion.errors && minAppVersion.errors.required && (minAppVersion.dirty || minAppVersion.touched)">Min
                    app version required.</span>
            </div>
        </div>
        <div *ngIf="!hubType" class="row my-3">
            <div class="col-md-6 mb-2">
                <ejs-textbox #seriesIdentifiers="ngModel" required pattern="^[0-9A-Z]+(,[0-9A-Z]+)*$"
                    name="seriesIdentifiers" placeholder="Series Identifiers" cssClass="e-outline"
                    [(ngModel)]="uploadDetails.seriesIdentifiers" id="seriesIdentifiers" floatLabelType="Auto">
                </ejs-textbox>
                <span class="e-error" *ngIf="seriesIdentifiers.errors && seriesIdentifiers.errors.pattern">
                    Invalid series identifers.</span>
                <span class="e-error"
                    *ngIf="seriesIdentifiers.errors && seriesIdentifiers.errors.required && (seriesIdentifiers.dirty || seriesIdentifiers.touched)">
                    At least one series identifier required.</span>
            </div>
            <div class="col-md-6 mt-2">
                <ejs-checkbox id="defaultSeriesIdentifier" label="Default Series Identifier"
                    [(ngModel)]="uploadDetails.defaultSeriesIdentifier"></ejs-checkbox>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #modelSelect>
    <div class="col-md-6">
        <div class="row my-3" *ngIf="modelDetails.data">
            <ejs-grid #grid id="bluetoothFirmwareReleaseResults" [allowSorting]="true" [allowPaging]="true"
                [pageSettings]="modelDetails.pageSettings" [dataSource]="modelDetails.data"
                [selectionSettings]="modelDetails.selectionSettings" (rowSelected)="selected($event)"
                (rowDeselected)="deselected($event)">
                <e-columns>
                    <e-column type="checkbox"></e-column>
                    <e-column headerText="Model Name" field="name" isPrimaryKey="true"></e-column>
                </e-columns>
            </ejs-grid>
        </div>
    </div>
</ng-template>

<ng-template #modelView>
    <div class='modal-body'>
        <ul *ngFor="let model of models">
            <li>{{model.name}}</li>
        </ul>
    </div>
</ng-template>

<ng-template #modalFooter>
    <div class="modal-footer">
        <div *ngIf="applianceType">
            <button ejs-button cssClass="e-primary" id="btnConfirm" name="confirm" 
                [disabled]="(uploadDetails.fileError || !uploadDetails.fileName) || !validateDirectAppliance()"
                (click)="saveChangesToApplianceFile()">
                Save
            </button>
        </div>
        <div *ngIf="bluetoothType">
            <button ejs-button cssClass="e-primary" id="btnConfirm" name="confirm" 
                [disabled]="(bluetoothFirmware == null && (uploadDetails.fileError || !uploadDetails.fileName)) || !validateBluetooth()"
                (click)="saveChangesToBluetoothFile()">
                Save
            </button>
        </div>
        <div *ngIf="zigbeeType">
            <button ejs-button cssClass="e-primary" id="btnConfirm" name="confirm"
                [disabled]="(zigbeeFirmware == null && (uploadDetails.fileError || !uploadDetails.fileName)) || !validateZigbee()"
                (click)="saveChangesToZigbeeFile()">
                Save
            </button>
        </div>
        <div *ngIf="hubType">
            <button ejs-button cssClass="e-primary" id="btnConfirm" name="confirm"
                [disabled]="uploadDetails.fileError || !uploadDetails.fileName" (click)="saveChangesToHubFile()">
                Save
            </button>
        </div>

        <button ejs-button (click)="activeModal.dismiss()">Cancel</button>
    </div>
</ng-template>
