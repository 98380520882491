<nav aria-label="breadcrumb" class="mt-1">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="float-start">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a routerLink="/home"><i class="fa fa-home"></i></a>
              </li>
              <li *ngFor="let crumb of breadcrumbs" class="breadcrumb-item">
                <a *ngIf="crumb.routerLink != ''" routerLink="{{crumb.routerLink}}">{{crumb.routerText}}</a>
                <a *ngIf="crumb.routerLink == ''">{{crumb.routerText}}</a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">{{pageName}}</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>
</nav>