import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'src/app/services/notification.service';
import { UserService } from 'src/app/services/user.service';
import { ErrorModalComponent } from '../error-modal/error-modal.component';
import { IdentityService } from 'src/app/services/common/identity.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NavigationComponent } from 'src/app/navigation/navigation.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
})
export class ProfileComponent {
  public allowUserProfileRead: boolean = false;

  @Input() public username;

  public userDetails = {
    id: null,
    emailAddress: null,
    userGroup: null,
    social: false,
  };

  public resetPasswordDetails = {
    isBusy: false,
    id: null,
    email: null,
    tenant: null,
    resetProfileUrl: null,
    results: null,
  };

  public errorDetails = {
    userId: null,
    errMessage: null,
    errStatus: null
  };

  public resetPasswordResults = {
    isBusy: false,
    data: null,
  };

  public onLinkCreate(args, instance) {
    instance.addIcon('append', 'e-icons e-open-link');
  }

  private userId = this.userService.getExternalId();

  constructor(private profileModal: NgbActiveModal,
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private userService: UserService,
    private identityService: IdentityService,) {
    this.getExternalUserDetails();
  }

  async ngOnInit() {
    this.allowUserProfileRead = await this.identityService.hasPermissionAsync('AdminUserProfileManagement_Read');
  }

  public logout() {
    this.userService.logout();
  }

  public resetPassword() {
    this.identityService.resetPassword()
      .subscribe(
        results => {
          this.activeModal.close("Password Reset Request Sent to Email.");
        },
        error => {
          this.activeModal.dismiss("An Error Occurred. Unable to Send Password Reset Request.");
        }
      );
  }

  public openModalError() {
    const modalRef = this.modalService.open(ErrorModalComponent, { size: 'md', backdrop: 'static' });
    modalRef.componentInstance.errorDetails = this.errorDetails;
  }

  private async getExternalUserDetails() {
    try {
      let user = await this.identityService.getExternalUser();
      this.userDetails.id = user.id;
      this.userDetails.emailAddress = user.email;
      this.resetPasswordDetails.email = user.email;
      this.userDetails.userGroup = user.group.name;
      this.userDetails.social = user.socialLogin;
    }
    catch (error) {
    }
  }

  get activeModal() {
    return this.profileModal;
  }
}
