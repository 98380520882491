import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { CardComponent } from './card/card.component';
import { PanelComponent } from './panel/panel.component';
import { MapComponent } from './map/map.component';
import { ErrorModalComponent } from './error-modal/error-modal.component';
import { ShortNumberPipe } from 'src/app/pipes/short-number.pipe';
import { DateFormatPipe } from 'src/app/pipes/date-format.pipe';
import { ButtonModule, CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { ProfileComponent } from './profile/profile.component';
import { FirmwareModalComponent } from './firmware-modal/firmware-modal.component';
import { GridModule, PageService, ResizeService, SortService } from '@syncfusion/ej2-angular-grids';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { ToastModule } from '@syncfusion/ej2-angular-notifications';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { AccordionModule, BreadcrumbModule } from '@syncfusion/ej2-angular-navigations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BulletTooltipService } from '@syncfusion/ej2-angular-charts';
import { ConfirmationModalDialogComponent } from './confirmation-modal-dialog/confirmation-modal-dialog.component';
import { TooltipModule } from '@syncfusion/ej2-angular-popups';
import { MapsModule, MarkerService, ZoomService } from '@syncfusion/ej2-angular-maps';
import { AnnouncementListComponent } from './announcement-list/announcement-list.component';
import { DeviceModalComponent } from './device-modal/device-modal.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [
        BreadcrumbComponent,
        CardComponent,
        PanelComponent,
        MapComponent,
        ErrorModalComponent,
        FirmwareModalComponent,
        DateFormatPipe,
        ShortNumberPipe,
        ProfileComponent,
        ConfirmationModalDialogComponent,
        AnnouncementListComponent,
        DeviceModalComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ButtonModule,
        CheckBoxModule,
        TextBoxModule,
        GridModule,
        ToastModule,
        DropDownListModule,
        DatePickerModule,
        NgbModule,
        AccordionModule,
        TooltipModule,
        MapsModule,
        BreadcrumbModule,
        TranslateModule,
    ],
    exports: [
        BreadcrumbComponent,
        CardComponent,
        PanelComponent,
        MapComponent,
        FirmwareModalComponent,
        DateFormatPipe,
        TranslateModule
    ],
    providers: [
        PageService,
        SortService,
        ResizeService,
        MarkerService,
        ZoomService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class SharedModule { }
