import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IdentityService } from 'src/app/services/common/identity.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-device-modal',
  templateUrl: './device-modal.component.html',
  styleUrl: './device-modal.component.css'
})
export class DeviceModalComponent implements OnInit {

  @Input() public deviceOverview;

  public allowDirectApplianceFirmwareReleaseGroupRead: boolean = false;
  public allowBluetoothApplianceFirmwareReleaseGroupRead: boolean = false;
  public allowHubFirmwareReleaseGroupRead: boolean = false;
  public allowHubManagementRead: boolean = false;
  public allowDirectApplianceManagementRead: boolean = false;
  public allowAdminApplianceManagementRead: boolean = false;

  public hubDetails = environment.routing.hubDetails;
  public applianceDetails = environment.routing.applianceDetails;
  public directApplianceDetails = environment.routing.directApplianceDetails;

  constructor(
    private identityService: IdentityService,
    public statusModel: NgbActiveModal
  ) { }

  public get activeModal() {
    return this.statusModel;
  }

  public async ngOnInit() {
    this.allowDirectApplianceFirmwareReleaseGroupRead = await this.identityService.hasPermissionAsync('AdminDirectApplianceFirmwareReleaseGroup_Read');
    this.allowHubFirmwareReleaseGroupRead = await this.identityService.hasPermissionAsync('AdminHubFirmwareReleaseGroup_Read');
    this.allowBluetoothApplianceFirmwareReleaseGroupRead = await this.identityService.hasPermissionAsync('AdminBluetoothFirmwareReleaseGroup_Read');
    this.allowHubManagementRead = await this.identityService.hasPermissionAsync('AdminHubManagement_Read');
    this.allowDirectApplianceManagementRead = await this.identityService.hasPermissionAsync('AdminDirectApplianceManagement_Read');
    this.allowAdminApplianceManagementRead = await this.identityService.hasPermissionAsync('AdminApplianceManagement_Read');
  }

  public navigateToNewPage(id: string) {
    this.activeModal.close(id);
  }

  public close() {
    this.activeModal.close();
  }

  public onLinkCreate(args, instance) {
    instance.addIcon('append', 'e-icons e-open-link');
  }
}
