import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ApplianceService } from 'src/app/services/appliance.service';
import { ConfirmationModalDialogComponent } from '../confirmation-modal-dialog/confirmation-modal-dialog.component';
import { ErrorCode } from 'src/app/types/enums/error-code';
import { FirmwareService } from 'src/app/services/firmware.service';
import { IModalDetails } from 'src/app/types/iModalDetails';
import { RowSelectEventArgs } from '@syncfusion/ej2-angular-grids';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-firmware-modal',
  templateUrl: './firmware-modal.component.html',
  styleUrls: ['./firmware-modal.component.css']
})
export class FirmwareModalComponent implements OnInit {

  @Input() public hubType;
  @Input() public bluetoothType;
  @Input() public applianceType;
  @Input() public zigbeeType;

  @Input() public bluetoothFirmware;
  @Input() public zigbeeFirmware;
  @Input() public applianceFirmware;
  @Input() public hubFirmware;

  @Input() public models;

  public userId: string;
  public externalId: string;

  public modelDetails = {
    data: null,
    pageSettings: { pageSize: 5, pageSizes: [5, 10, 20] },
    selectionSettings: { persistSelection: true },
  }

  public uploadDetails = {
    fileName: null,
    releaseNotes: null,
    minAppVersion: null,
    isGeneralRelease: false,
    size: null,
    file: null,
    fileError: false,
    textColor: 'dark',
    seriesIdentifiers: null,
    defaultSeriesIdentifier: null,
    hardwareRevision: null,
  }

  public selectedModels = [];
  public modalCount = 1;

  constructor(private firmwareModel: NgbActiveModal,
    private firmwareService: FirmwareService,
    private applianceService: ApplianceService,
    private userService: UserService,
    private modalService: NgbModal) { }

  get activeModal() {
    return this.firmwareModel;
  }

  public path: Object = {
  };

  ngOnInit(): void {
    if (this.bluetoothType) {
      this.getBluetoothModels();
    }
    else if (this.zigbeeType) {
      this.getZigbeeModels();
    }

    this.externalId = this.userService.getExternalId();
    this.getExternalUserDetails(this.externalId);
  }

  onFileSelected(event) {
    const file: File = event.target.files[0];
    if (file) {
      if (!this.validateFileExt(file) || !this.validateFileSize(file) || !this.validateFileName(file)) {
        this.uploadDetails.fileError = true;
        this.uploadDetails.textColor = 'danger';
        return false;
      }
      else if (this.validateFileExt(file) && this.validateFileSize(file) && this.validateFileName(file)) {
        this.uploadDetails.fileName = file.name;
        this.uploadDetails.textColor = 'dark';
        this.uploadDetails.size = file.size;
        this.uploadDetails.fileError = false;

        const formData = new FormData();
        formData.append("Firmware", file, this.uploadDetails.fileName);
        this.uploadDetails.file = formData;
      }
    }
  }

  private validateFileExt(file: File) {
    var ext = file.name.substring(file.name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() === 'zip') {
      if (this.bluetoothType || this.zigbeeType) {
        return true;
      }
      else if (this.applianceType === environment.products.dimplexWifi ||
        this.applianceType === environment.products.intuisOfrWifi ||
        this.applianceType === environment.products.mullerPhWifi ||
        this.applianceType === environment.products.optiflameV2 ||
        this.applianceType === environment.products.optiflameV3 ||
        this.applianceType === environment.products.optiflameV4) {
        return true;
      }
      else if (this.hubType === 'Gateway V2' || this.hubType === 'LTEGateway V1') {
        return true;
      }
      else {
        this.uploadDetails.fileName = "Invalid File Format!!"
        return false;
      }
    }
    else if (ext.toLowerCase() === 'bin') {
      if (this.applianceType !== null &&
        this.applianceType !== undefined &&
        this.applianceType !== environment.products.dimplexWifi &&
        this.applianceType !== environment.products.intuisOfrWifi &&
        this.applianceType !== environment.products.mullerPhWifi &&
        this.applianceType !== environment.products.optiflameV2 &&
        this.applianceType !== environment.products.optiflameV3 &&
        this.applianceType !== environment.products.optiflameV4) {
        return true;
      }
      else if (this.hubType !== null &&
        this.hubType !== undefined &&
        this.hubType !== 'Gateway V2' &&
        this.hubType !== 'LTEGateway V1') {
        return true;
      }
      else {
        this.uploadDetails.fileName = "Invalid File Format!!"
        return false;
      }
    }
    else {
      this.uploadDetails.fileName = "Invalid File Format!!"
      return false;
    }
  }

  private validateFileSize(file: File) {
    var size = file.size;
    if (size < 33554432) {
      return true;
    }
    else {
      this.uploadDetails.fileName = "Maximum File Size Exceeded!!"
      return false;
    }
  }

  private validateFileName(file: File) {
    var name = file.name;
    var zipRegex = /^EP\d\d\d\d_[a-zA-Z]+_[0-9]+_[0-9]+_[a-zA-Z][a-zA-Z][a-zA-Z]_\d\d\d\d\d\d(_TST)?(.zip)?$/;
    var mullerRegex = /^MR\d\d\d\d_[a-zA-Z]+_[0-9]+_[0-9]+_[a-zA-Z][a-zA-Z][a-zA-Z]_\d\d\d\d\d\d(_TST)?(.zip)?$/;
    var intuisRegex = /^MR\d\d\d\d_[a-zA-Z]+_[0-9]+_[0-9]+_[a-zA-Z][a-zA-Z][a-zA-Z]_\d\d\d\d\d\d(_TST)?(.zip)?$/;
    var optiflameRegex = /^[a-zA-Z][a-zA-Z]\d\d\d\d_[a-zA-Z]+_[0-9]+_[0-9]+_[a-zA-Z][a-zA-Z][a-zA-Z]_\d\d\d\d\d\d(_TST)?(.zip)?$/;
    var binRegex = /^[a-zA-Z0-9]+[0-9]+\.[0-9]+\.[0-9]+.*$/;
    if (this.hubType == 'Gateway V2' || this.hubType == 'LTEGateway V1'
      || this.applianceType === environment.products.dimplexWifi
      || this.bluetoothType
      || this.zigbeeType) {
      if (name.match(zipRegex)) {
        return true;
      }
      else {
        this.uploadDetails.fileName = "Invalid File Name!!"
        return false;
      }
    }
    else if (this.applianceType === environment.products.mullerPhWifi) {
      if (name.match(mullerRegex)) {
        return true;
      }
      else {
        this.uploadDetails.fileName = "Invalid File Name!!"
        return false;
      }
    }
    else if (this.applianceType === environment.products.intuisOfrWifi) {
      if (name.match(intuisRegex)) {
        return true;
      }
      else {
        this.uploadDetails.fileName = "Invalid File Name!!"
        return false;
      }
    }
    else if (this.applianceType === environment.products.optiflameV2 || this.applianceType === environment.products.optiflameV3 || this.applianceType === environment.products.optiflameV4) {
      if (name.match(optiflameRegex)) {
        return true;
      }
      else {
        this.uploadDetails.fileName = "Invalid File Name!!"
        return false;
      }
    }
    else if (this.hubType != 'Gateway V2' || this.hubType != 'LTEGateway V1'
      || this.applianceType != environment.products.dimplexWifi
      || this.applianceType != environment.products.intuisOfrWifi
      || this.applianceType != environment.products.mullerPhWifi
      || this.applianceType != environment.products.optiflameV2
      || this.applianceType != environment.products.optiflameV3
      || this.applianceType != environment.products.optiflameV4) {
      if (name.match(binRegex)) {
        return true;
      }
      else {
        this.uploadDetails.fileName = "Invalid File Name!!"
        return false;
      }
    }
    else {
      this.uploadDetails.fileName = "Invalid File Name!!"
      return false;
    }
  }

  public validateDirectAppliance(): boolean {
    return this.validateSeriesIdentifiers();
  }

  public validateBluetooth(): boolean {
    return this.validateSeriesIdentifiers() && this.validateMinAppVersion();
  }

  public validateZigbee(): boolean {
    return this.validateSeriesIdentifiers() && this.validateMinAppVersion();
  }

  private validateMinAppVersion(): boolean {
    const minAppVersionRegex = /^[0-9]+.[0-9]+.[0-9]+$/;
    return minAppVersionRegex.test(this.uploadDetails.minAppVersion);
  }

  private validateSeriesIdentifiers(): boolean {
    const seriesIdentifiersRegex = /^[0-9A-Z]+(,[0-9A-Z]+)*$/;
    return seriesIdentifiersRegex.test(this.uploadDetails.seriesIdentifiers);
  }

  public selected(args: RowSelectEventArgs) {
    if (!Array.isArray(args.data)) {
      if (!this.selectedModels.find(element => element == args.data)) {
        this.selectedModels.push(args.data);
      }
    }
    else {
      args.data.forEach(element => {
        if (!this.selectedModels.find(entry => entry == element)) {
          this.selectedModels.push(element);
        }
      })
    }
  }

  public deselected(args: RowSelectEventArgs) {
    if (!Array.isArray(args.data)) {
      if (this.selectedModels.find(element => element == args.data)) {
        const index = this.selectedModels.indexOf(args.data);
        if (index !== -1) {
          this.selectedModels.splice(index, 1);
        }
      }
    }
    else {
      args.data.forEach(element => {
        if (this.selectedModels.find(entry => entry == element)) {
          const index = this.selectedModels.indexOf(element);
          if (index !== -1) {
            this.selectedModels.splice(index, 1);
          }
        }
      })
    }
  }

  public saveChangesToBluetoothFile() {
    if (this.bluetoothFirmware == null) {
      this.CheckIfFileExists();
    }
  }

  public saveChangesToZigbeeFile() {
    if (this.zigbeeFirmware == null) {
      this.CheckIfFileExists();
    }
  }

  public saveChangesToApplianceFile() {
    if (this.applianceFirmware == null) {
      this.CheckIfFileExists();
    }
  }

  public saveChangesToHubFile() {
    if (this.hubFirmware == null) {
      this.CheckIfFileExists();
    }
  }

  public getBluetoothModels() {
    this.applianceService.GetBluetoothModels(this.bluetoothType).subscribe(
      models => this.modelDetails.data = models,
    );
  }

  public getZigbeeModels() {
    this.applianceService.GetBluetoothModels(this.zigbeeType).subscribe(
      models => this.modelDetails.data = models,
    );
  }

  public uploadBluetoothFirmwareFile() {
    let models: string[] = [];
    this.selectedModels.forEach(model => {
      models.push(model.id);
    })
    let firmware = {
      blobName: this.uploadDetails.fileName,
      isEnabled: false,
      isGeneralRelease: this.uploadDetails.isGeneralRelease,
      minAppVersion: this.uploadDetails.minAppVersion,
      version: '',
      sizeInBytes: this.uploadDetails.size,
      releaseNotes: this.uploadDetails.releaseNotes,
      uploadedOn: new Date(),
      uploadedBy: this.userId,
      productTypeId: this.bluetoothType,
      models: models,
      seriesIdentifiers: this.uploadDetails.seriesIdentifiers,
      defaultSeriesIdentifier: this.uploadDetails.defaultSeriesIdentifier,
    }
    let file = this.uploadDetails.file;
    this.firmwareService.UploadBluetoothFirmwareFile(file).subscribe(
      upload => {
        firmware.version = upload.version;
        this.firmwareService.AddBluetoothFirmwareRelease(firmware).subscribe(
          () => {
            this.activeModal.close(firmware.blobName);
          },
          error => {
            this.processUploadError(error);
          }
        );
      },
      error => {
        this.processUploadError(error);
      }
    );
  }

  public uploadZigbeeFirmwareFile() {
    let models: string[] = [];
    this.selectedModels.forEach(model => {
      models.push(model.id);
    })
    let firmware = {
      blobName: this.uploadDetails.fileName,
      isEnabled: false,
      isGeneralRelease: this.uploadDetails.isGeneralRelease,
      minAppVersion: this.uploadDetails.minAppVersion,
      version: '',
      sizeInBytes: this.uploadDetails.size,
      releaseNotes: this.uploadDetails.releaseNotes,
      uploadedOn: new Date(),
      uploadedBy: this.userId,
      productTypeId: this.zigbeeType,
      models: models,
      seriesIdentifiers: this.uploadDetails.seriesIdentifiers,
      defaultSeriesIdentifier: this.uploadDetails.defaultSeriesIdentifier,
    }
    let file = this.uploadDetails.file;
    this.firmwareService.UploadZigbeeFirmwareFile(file).subscribe(
      upload => {
        firmware.version = upload.version;
        this.firmwareService.AddZigbeeFirmwareRelease(firmware).subscribe(
          () => {
            this.activeModal.close(firmware.blobName);
          },
          error => {
            this.processUploadError(error);
          }
        );
      },
      error => {
        this.processUploadError(error);
      }
    );
  }

  public uploadApplianceFirmwareFile() {
    let firmware = {
      blobName: this.uploadDetails.fileName,
      isEnabled: false,
      isGeneralRelease: this.uploadDetails.isGeneralRelease,
      version: '',
      sizeInBytes: this.uploadDetails.size,
      releaseNotes: this.uploadDetails.releaseNotes,
      uploadedOn: new Date(),
      uploadedBy: this.userId,
      productTypeId: this.applianceType,
      hardwareRevision: this.uploadDetails.hardwareRevision != null ? this.uploadDetails.hardwareRevision : '1.00',
      seriesIdentifiers: this.uploadDetails.seriesIdentifiers,
      defaultSeriesIdentifier: this.uploadDetails.defaultSeriesIdentifier,
    }
    if (firmware.isGeneralRelease) {firmware.isEnabled = true}
    let file = this.uploadDetails.file;
    this.firmwareService.UploadApplianceFirmwareFile(file, this.applianceType).subscribe(
      upload => {
        firmware.version = upload.version;
        firmware.blobName = upload.blobName;
        this.firmwareService.AddApplianceFirmwareRelease(firmware).subscribe(
          () => {
            this.activeModal.close(upload.fileName);
          },
          error => {
            this.processUploadError(error);
          }
        )
      },
      error => {
        this.processUploadError(error);
      }
    );
  }

  public uploadHubFirmwareFile() {
    let firmware = {
      blobName: this.uploadDetails.fileName,
      fileName: this.uploadDetails.fileName.split('_')[1],
      isEnabled: false,
      isGeneralRelease: this.uploadDetails.isGeneralRelease,
      minAppVersion: this.uploadDetails.minAppVersion,
      version: '',
      sizeInBytes: this.uploadDetails.size,
      releaseNotes: this.uploadDetails.releaseNotes,
      uploadedOn: new Date(),
      uploadedBy: this.userId,
      hardwareRevision: this.uploadDetails.hardwareRevision != null ? this.uploadDetails.hardwareRevision : '1.00',
      hubType: this.hubType,
    }
    if (firmware.isGeneralRelease) {firmware.isEnabled = true}
    let file = this.uploadDetails.file;
    this.firmwareService.UploadHubFirmwareFile(file).subscribe(
      upload => {
        firmware.version = upload.version;
        firmware.blobName = upload.blobName;
        this.firmwareService.AddHubFirmwareRelease(firmware).subscribe(
          () => {
            this.activeModal.close(firmware.blobName);
          },
          error => {
            this.processUploadError(error);
          },
        )
      },
      error => {
        this.processUploadError(error);
      }
    );
  }

  public uploadGatewayV2FirmwareFile() {
    let firmware = {
      blobName: 'gatewayv2/' + this.uploadDetails.fileName,
      fileName: this.uploadDetails.fileName,
      isEnabled: false,
      isGeneralRelease: this.uploadDetails.isGeneralRelease,
      version: '',
      sizeInBytes: this.uploadDetails.size,
      releaseNotes: this.uploadDetails.releaseNotes,
      uploadedOn: new Date(),
      uploadedBy: this.userId,
      hubType: this.hubType,
      hardwareRevision: this.uploadDetails.hardwareRevision != null ? this.uploadDetails.hardwareRevision : '1.00',
      gatewayFirmwareFile: null,
    }
    if (firmware.isGeneralRelease) {firmware.isEnabled = true}
    let file = this.uploadDetails.file;
    this.firmwareService.UploadGatewayV2FirmwareFile(file).subscribe(
      upload => {
        let version = this.uploadDetails.fileName.split('.')[0];
        firmware.version = version;
        firmware.gatewayFirmwareFile = upload;
        this.firmwareService.AddHubFirmwareRelease(firmware).subscribe(
          () => {
            this.activeModal.close(firmware.blobName);
          },
          error => {
            this.processUploadError(error);
          },
        )
      },
      error => {
        this.processUploadError(error);
      }
    );
  }

  public uploadGatewayV1FirmwareFile() {
    let firmware = {
      blobName: 'lteGateway/' + this.uploadDetails.fileName,
      fileName: this.uploadDetails.fileName,
      isEnabled: false,
      isGeneralRelease: this.uploadDetails.isGeneralRelease,
      version: '',
      sizeInBytes: this.uploadDetails.size,
      releaseNotes: this.uploadDetails.releaseNotes,
      uploadedOn: new Date(),
      uploadedBy: this.userId,
      hardwareRevision: this.uploadDetails.hardwareRevision != null ? this.uploadDetails.hardwareRevision : '1.00',
      hubType: this.hubType,
      gatewayFirmwareFile: null,
    }
    if (firmware.isGeneralRelease) {firmware.isEnabled = true}
    let file = this.uploadDetails.file;
    this.firmwareService.UploadGatewayV1FirmwareFile(file).subscribe(
      upload => {
        let version = this.uploadDetails.fileName.split('.')[0];
        firmware.version = version;
        firmware.gatewayFirmwareFile = upload;
        this.firmwareService.AddHubFirmwareRelease(firmware).subscribe(
          success => {
            this.activeModal.close(firmware.blobName);
          },
          error => {
            this.processUploadError(error);
          }
        )
      },
      error => {
        this.processUploadError(error);
      }
    );
  }

  public async CheckIfFileExists() {
    let folderName = '';
    let blobName = '';
    let containerName = '';
    if (this.applianceType) {
      folderName = 'directAppliance';
      blobName = this.uploadDetails.fileName;
      containerName = 'firmwares'
    }
    else if (this.hubType) {
      switch (this.hubType) {
        case 'LTEGateway V1': folderName = 'lteGateway'; break;
        case 'Gateway V2': folderName = 'gatewayv2'; break;
        default: folderName = ''; break;
      }
      blobName = this.uploadDetails.fileName;
      containerName = 'firmwares'
    }
    else if (this.bluetoothType) {
      folderName = '';
      blobName = this.uploadDetails.fileName;
      containerName = 'bluetooth-firmware'
    }
    else if (this.zigbeeType) {
      folderName = '';
      blobName = this.uploadDetails.fileName;
      containerName = 'zigbee-firmware'
    }
    if (this.modalCount === 1) {
      this.modalCount++;
      try {
        let existing = await this.firmwareService.CheckIfFileExists(containerName, blobName, folderName).toPromise();
        if (existing) {
          let data = {} as IModalDetails;
          data.message = 'This firmware file already exists, replace the existing one?';
          data.details = null;
          data.title = 'Existing Firmware';
          const confirmModal = this.modalService.open(ConfirmationModalDialogComponent, { size: 'md', backdrop: 'static' });
          confirmModal.componentInstance.statusDetails = data;
          confirmModal.closed.subscribe(
            () => {
              this.processUploadDeviceType();
              this.modalCount--;
            },
          );
          confirmModal.dismissed.subscribe(
            () => { 
              this.activeModal.dismiss("Did not replace existing firmware file.");
              this.modalCount--;
            }
          )
        }
        else { this.processUploadDeviceType(); }
      }
      catch (error) {
        this.processUploadError(error);
      }
    }
  }

  public processUploadDeviceType() {
    if (this.applianceType) {
      this.uploadApplianceFirmwareFile();
    }
    else if (this.bluetoothType) {
      this.uploadBluetoothFirmwareFile();
    }
    else if (this.zigbeeType) {
      this.uploadZigbeeFirmwareFile();
    }    
    else if (this.hubType) {
      switch (this.hubType) {
        case 'LTEGateway V1': this.uploadGatewayV1FirmwareFile(); break;
        case 'Gateway V2': this.uploadGatewayV2FirmwareFile(); break;
        default: this.uploadHubFirmwareFile(); break;
      }
    }
  }

  public processUploadError(error: any) {
    let uniMessage = 'Firmware file has not been uploaded to the portal';
    let data = {} as IModalDetails;
    data.title = 'Firmware Upload Error';
    data.message = `${uniMessage}.`;
    const modalRef = this.modalService.open(ConfirmationModalDialogComponent, { size: 'sm', backdrop: 'static' });
    modalRef.componentInstance.firmwareError = true;

    if (error.error?.ErrorCode) {
      switch (error.error.ErrorCode) {
        case ErrorCode.ZipFileHasFolders: 
          data.details = { Error: `Zip file cannot contain folders` };
          modalRef.componentInstance.statusDetails = data;
          this.activeModal.dismiss();
          break;
        case ErrorCode.NoReleaseConfig: 
          data.details = { Error: `Zip file should contain a file called 'release_config.json'.`};
          modalRef.componentInstance.statusDetails = data;
          this.activeModal.dismiss();
          break;
        case ErrorCode.NoFileWithName: 
          data.details = { Error: `No file in zip with name '${error.error.ErrorMessage}'.`};
          modalRef.componentInstance.statusDetails = data;
          this.activeModal.dismiss();
          break;
        case ErrorCode.NoDataInJson: 
          data.details = { Error: `release_config.json should not be empty and have at least details of one firmware file.`};
          modalRef.componentInstance.statusDetails = data;
          this.activeModal.dismiss();
          break;
        case ErrorCode.IncorrectFileCount: 
          data.details = { Error: `File count does not match release_config.json.`};
          modalRef.componentInstance.statusDetails = data;
          this.activeModal.dismiss();
          break;
        case ErrorCode.FileDoesNotMatchFormat: 
          data.details = { Error: `The file names in the release_config.json should match the proper regex expression.`};
          modalRef.componentInstance.statusDetails = data;
          this.activeModal.dismiss();
          break;
        case ErrorCode.FileDoesNotHaveExtension: 
          data.details = { Error: `Items in release_config.json file should have a file extension as part of the File Name.`};
          modalRef.componentInstance.statusDetails = data;
          this.activeModal.dismiss();
          break;
        case ErrorCode.FilehasNoModule: 
          data.details = { Error: `Items in release_config.json should have a 'module' defined.`};
          modalRef.componentInstance.statusDetails = data;
          this.activeModal.dismiss();
          break;
        case ErrorCode.SeqIdInvalidOrNull: 
          data.details = { Error: `Items in release_config.json file should have a valid "seqid"`};
          modalRef.componentInstance.statusDetails = data;
          this.activeModal.dismiss();
          break;
        case ErrorCode.DuplicateSeqId: 
          data.details = { Error: `Items in release_config.json should have a Unique "seqid".`};
          modalRef.componentInstance.statusDetails = data;
          this.activeModal.dismiss();
          break;
        case ErrorCode.DuplicateFileName: 
          data.details = { Error: `Items in release_config.json should have Unique file names.`};
          modalRef.componentInstance.statusDetails = data;
          this.activeModal.dismiss();
          break;
        case ErrorCode.FileHasNoVersion: 
          data.details = { Error: `Items in release_config.json should have a version number.`};
          modalRef.componentInstance.statusDetails = data;
          this.activeModal.dismiss();
          break;
        case ErrorCode.FileHasInvalidVersion: 
          data.details = { Error: `Items in release_config.json should have a valid version number.`};
          modalRef.componentInstance.statusDetails = data;
          this.activeModal.dismiss();
          break;
        case ErrorCode.NoMatchingFileFound: 
          data.details = { Error: `release_config.json defines item '${error.error.ErrorMessage}' but no file in zip with that name.`};
          modalRef.componentInstance.statusDetails = data;
          this.activeModal.dismiss();
          break;
        case ErrorCode.TestBuildNotAllowed: 
          data.details = { Error: `Items in release_config.json containing TST in their file name are not allowed.`};
          modalRef.componentInstance.statusDetails = data;
          this.activeModal.dismiss();
          break;
        case ErrorCode.EmptyFileNotAllowed: 
          data.details = { Error: `Individual files in the zip file should not be empty.`};
          modalRef.componentInstance.statusDetails = data;
          this.activeModal.dismiss();
          break;
        case ErrorCode.ProductDoesNotHaveModels: 
          data.details = { Error: `Must select at least one product model.`};
          modalRef.componentInstance.statusDetails = data;
          this.activeModal.dismiss();
          break;
        case ErrorCode.InvalidUserId: 
          data.details = { Error: `Invalid user.`};
          modalRef.componentInstance.statusDetails = data;
          this.activeModal.dismiss();
          break;
        case ErrorCode.InvalidProductId: 
          data.details = { Error: `Invalid product Id.`};
          modalRef.componentInstance.statusDetails = data;
          this.activeModal.dismiss();
          break;
        case ErrorCode.InvalidFileName: 
          data.details = { Error: `Invalid firmware file name.`};
          modalRef.componentInstance.statusDetails = data;
          this.activeModal.dismiss();
          break;
        case ErrorCode.InvalidReleaseConfig: 
          data.details = { Error: `Invalid release config.`};
          modalRef.componentInstance.statusDetails = data;
          this.activeModal.dismiss();
          break;
        default: 
          data.details = { Error: error.error.ErrorMessage};
          modalRef.componentInstance.statusDetails = data;
          this.activeModal.dismiss();
          break;
      }
    }
    else {
      data.details = { Error: `An unexpected error occured while uploading the firmware file.`};
      modalRef.componentInstance.statusDetails = data;
      this.activeModal.dismiss();
    }
  }

  private getExternalUserDetails(id: string) {
    this.userService.getExternalUser(id).subscribe(
      user => {
        this.userId = user.id;
      });
  }

  private boldTransform(value: string) {
    const regex = new RegExp(/([*])(?:(?=(\\?))\2.)*?\1/g, 'gi');
    return value.replace(regex, (match) => `<strong>${match.replace(/\*/g,'')}</strong>`);
  }
}
