<div>
  <div class='modal-header'>
    <div *ngIf="errorDetails != null; then hubNotFound"></div>
    <div *ngIf="username != null; then unsavedChanges"></div>
    <div *ngIf="email != null; then unsavedChanges"></div>
    <div *ngIf="errorDetails == null && username == null && email == null && authenticationDetails == null; then unknownError"></div>
    <div *ngIf="authenticationDetails != null; then unauthenticated"></div>

    <button type='button' class='btn-close' aria-label='Close' (click)='activeModal.dismiss()'>
    </button>
  </div>
  <div *ngIf="errorDetails != null; then hubError"></div>
  <div *ngIf="username != null; then editError"></div>
  <div *ngIf="email != null; then contactError"></div>
  <div *ngIf="authenticationDetails != null; then authenticationError"></div>
  <div *ngIf="errorDetails == null && username == null && email == null && authenticationDetails == null; then noError"></div>

  <div *ngIf="errorDetails != null; then dismiss"></div>
  <div *ngIf="email !=null || username != null; then userConfirm"></div>
  <ng-template #dismiss>
    <div class="modal-footer">
      <button ejs-button (click)="activeModal.close(false)">Close</button>
    </div>
  </ng-template>
  <ng-template #userConfirm>
    <div class="modal-footer">
      <button ejs-button class="e-primary" (click)="activeModal.close(false)">Stay</button>
      <button ejs-button (click)="activeModal.close(true)">Leave</button>
    </div>
  </ng-template>

</div>

<ng-template #hubError>
    <div class='modal-body'>
        <p>{{ errorDetails.hubId }} - {{ errorDetails.errMessage }}</p>
        <p>Status Code - {{ errorDetails.errStatus }}</p>
    </div>
</ng-template>

<ng-template #editError>
    <div class='modal-body'>
        <p>You have unsaved changes to {{ username }}, are you sure you want to leave this page?</p>
    </div>
</ng-template>

<ng-template #contactError>
    <div class='modal-body'>
        <p>You have an unsent message to {{ email }}, are you sure you want to leave this page?</p>
    </div>
</ng-template>

<ng-template #authenticationError>
  <div class='modal-body'>
    <p>You dont have the necessary permissions to access this page, please contact you system administrator</p>
  </div>
</ng-template>

<ng-template #noError>
    <div class='modal-body'>
        <p>An unknown error has occured</p>
    </div>
</ng-template>

<ng-template #unknownError>
    <h5 class='modal-title fw-bold' id='modal-basic-title'>Sorry, something went wrong</h5>
</ng-template>

<ng-template #hubNotFound>
    <h5 class='modal-title fw-bold' id='modal-basic-title'>There was a problem loading this hub</h5>
</ng-template>

<ng-template #unsavedChanges>
    <h5 class='modal-title fw-bold' id='modal-basic-title'>You have unsaved changes</h5>
</ng-template>

<ng-template #unauthenticated>
  <h5 class='modal-title fw-bold' id='modal-basic-title'>You do not have the neccessary permissions:</h5>
</ng-template>


