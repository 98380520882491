<div class='modal-header'>
    <h5 class="modal-title" id="modal-basic-title">Account Details</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>
<div class='modal-body'>
    <div class='row pb-2'>
        <ejs-textbox placeholder="Username" floatLabelType="Always" id="modalUserUsername" value="{{username}}"
            readonly></ejs-textbox>
    </div>
    <div class='row pb-2'>
        
        <ejs-textbox *ngIf='allowUserProfileRead' placeholder="Email Address" floatLabelType="Always"
            id="modalUserEmailAddress" value="{{userDetails.emailAddress}}" readonly
            routerLink='../../../usermanagement/users/{{userDetails.id}}' (click)='activeModal.dismiss()'
            (created)="onLinkCreate($event, textboxEmail)" #textboxEmail></ejs-textbox>

        <ejs-textbox *ngIf='!allowUserProfileRead' placeholder="Email Address" floatLabelType="Always"
            id="modalUserEmailAddress" value="{{userDetails.emailAddress}}" readonly></ejs-textbox>
    </div>
    <div class='row pb-2'>
        <ejs-textbox placeholder="User Role" floatLabelType="Always" id="modalUserRole"
            value="{{userDetails.userGroup}}" readonly></ejs-textbox>
    </div>
</div>
<div class='modal-footer'>
    <div class="float-end">
        <button ejs-button cssClass="e-primary" [disabled]="userDetails.emailAddress == null || userDetails.social"
            (click)="resetPassword()">Reset Password</button>
        <button ejs-button cssClass="e-danger ms-2" (click)="logout()">Logout</button>
    </div>
</div>