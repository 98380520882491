import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'dateFormat'
})

export class DateFormatPipe implements PipeTransform {

  constructor(private translateService: TranslateService) {
  }

  transform(value?: Date): string {
    let format;
    let locale;
    
    this.translateService.get('date.displayFormat').subscribe(v => {format = v;});
    this.translateService.get('date.locale').subscribe(v => {locale = v;});
        
        if (value != null) {
            var d = formatDate(value, format, locale);
            return d;
        } else {
            return "";
        }
  }
}

