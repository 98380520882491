<div>
  <div class='modal-header'>
    <h5 class='modal-title' id='modal-basic-title'>{{ this.statusDetails.title }}</h5>
    <button type='button' class='btn-close' aria-label='Close' (click)='activeModal.dismiss()'>
    </button>
  </div>
  <div class='modal-body'>
    <p>{{ this.statusDetails.message }}</p>
    <div *ngIf="this.statusDetails.details">
      <div class="mb-2" *ngFor="let item of this.statusDetails.details | keyvalue">
        <ejs-textbox placeholder="{{item.key}}" value="{{item.value}}" floatLabelType="Always" readonly></ejs-textbox>
      </div>
    </div>
    <div *ngIf="this.statusDetails.title == 'Delete User Group' && this.statusDetails.details.numberOfUsers > 0">
      <ejs-dropdownlist placeholder="New Group" cssClass="e-outline" [dataSource]='groupList' [fields]='fields'
        id="newGroup" [(ngModel)]='newGroupId' name="newGroup"></ejs-dropdownlist>
    </div>
  </div>
  <div class="modal-footer">
    <ng-container
      *ngIf="this.statusDetails.title == 'Delete User Group' && this.statusDetails.details.numberOfUsers > 0; then deleteGroup else otherModal"></ng-container>


    <div *ngIf="!this.firmwareError">
      <button ejs-button (click)="activeModal.dismiss()">Close</button>
    </div>
  </div>
</div>

<ng-template #otherModal>
  <button ejs-button cssClass="e-primary" id="btnConfirm" name="confirm"
    (click)="confirmClick(); this.activeModal.close(true);">
    Confirm
  </button>
</ng-template>
<ng-template #deleteGroup>
  <button ejs-button cssClass="e-primary" id="btnConfirm" name="confirm" [disabled]="!newGroupId"
    (click)="confirmClick(); this.activeModal.close(newGroupId);">
    Confirm
  </button>
</ng-template>