<div class="row">
    <div class="blocked-alert">
      <app-panel [isBusy]="false" [removePadding]="false">
        <div class="text-center">
          <h2>Your Account is Blocked</h2>
          <p>The Account you are using has been blocked. Please Contact the Administrator</p>
          <br>
          <div class="row">
            <div class="col-lg-6">
              <button ejs-button cssClass="e-danger" (click)="logOut()">
                Log Out
              </button>
            </div>
          </div>
        </div>
      </app-panel>
    </div>
  </div>
